import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { Link } from 'gatsby'

export const StyledContactPage = styled.div`
  background-color: #F9F9F9;
  padding-bottom: 32px;
  .card-wrap:hover {
    svg {
      transform: scale(1.1);
      margin-right: 0;
    }
  }
  h3 {
    margin-bottom: 0;
  }
  hr {
    margin: 16px auto !important;
  }
  h2 {
    text-align: center;
    margin-top: 48px;
    font-size: 48px;
  }
  input::placeholder,
  textarea::placeholder,
  label {
    opacity: 0.75;
    color: #1e1e1e;
  }
  input::placeholder,
  textarea::placeholder,
  input,
  textarea {
    font-family: Ringside !important;
    font-size: 16px;
  }
  .ambaum-contact-form-wrap form {
    padding: 32px 16px;
    margin-top: -100px;
    position: relative;
    input, textarea {
      border-width: 2px;
      border-color: #1e1e1e;
      &:focus {
        outline: 0;
      }
    }
      #mce-success-response{

      }
      #mce-error-response{
        color:red;
      }
      div[aria-hidden="true"]{
        position: absolute;
        left: -5000px;
      }
      input[type="submit"]{
        cursor: pointer;
      }
    
    ${media.md`
      padding: 32px 48px;
      margin-top: -50%;
    `}
  }
`

export const LogoBar = styled.div`
  display: flex;
  flex-flow: row wrap;
  > div {
    flex: 1;
    padding: 16px;
    min-width: 50%;
    ${media.md`
      min-width: 25%;
    `}
  }
`

export const TextBox = styled(Link)`
  background: #ffffff;
  height: 100%;
  padding: 32px 64px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  display: flex;
  flex-flow: column nowrap;
  text-decoration: none;
  justify-content: space-between;
  color: #333333;
  transition: 0.2s;
  > hr {
    border: none;
    background: transparent;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    width: 30%;
    margin: auto;
  }
  > strong {
    font-size: 22px;
    text-align: center;
  }
  > div {
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
    transition: 0.2s;
  }
  &:hover {
    cursor: pointer;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    > div {
      svg {
        fill: #489FDB;
        transform: scale(1.1);
      }
    }
  }
`

export const SquareImg = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  height: 0;
  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`

export const Margin = styled.div`
  margin-top: ${({ margin }) => margin}px;
`

export const StyledSocialBar = styled.div`
  display: flex;
  justify-content: center;
  a {
    color: #489FDB;
    margin: 8px;
    transition: 0.2s;
    &:hover {
      color: #206ea2;
    }
  }
  ${media.md`
    flex-flow: column nowrap;
    transform: translateX(${({ offset }) => offset !== undefined ? offset : '-100%'});
    padding: 0 16px 64px;
    position: absolute;
    left: 0;
    bottom: 0;
  `}
`