import styled from 'styled-components'
import { media } from "styled-bootstrap-grid"

export const StyledClientGrid = styled.div`
  > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    padding-top: 32px;
    > p {
        margin: 0;
    }
    > li {
        width: 100%;
        position: relative;
        height: 0;
        padding-top: 55%;
        margin: 8px;
        > a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            img {
                height: 100%;
            }
        }
    }
    ${media.md`
      > li {
        width: calc((100% / ${({ tilesPerRow }) => tilesPerRow}) - 16px);
        padding-top: calc(55% / ${({ tilesPerRow }) => tilesPerRow});
      }  
      > li:first-child,
      > li:nth-child(2) {
        width: calc(50% - 16px);
        padding-top: 28%;
      }
    `}
  }
`
