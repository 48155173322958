import styled from 'styled-components'

export const HomepageStyles = `
  max-width: 1440px;
  margin: auto;
  > .container {
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    .section__title, .section__description {
      border-left: 5px solid #FFC600;
      padding-left: 1.25rem;
      margin: 0;
    }
    .section__title {
      display: inline-block;
      font-weight: 500;
      font-size: 50px;
      line-height: 1.167;
      letter-spacing: -1px;
      padding-bottom: 24px;
      margin-top: 140px;
    }
    .section__description {
      max-width: 1000px;
      font-size: 22px;
      line-height: 1.54;
      margin-bottom: 70px;
    }
  }
`;

export const SectionPadding = styled.div`
  padding: 12px;
`