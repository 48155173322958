import React, { useState } from 'react'
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import { navigate } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Container, Col, Row } from "styled-bootstrap-grid"
import parse from 'html-react-parser'
import { CardFooter } from '../../components/Card/styles'
import {
    TextBox,
    SquareImg,
    Margin,
    StyledSocialBar
} from "../ContactPage/styles";
import { Title, SubTitle, Content, Form, H1Span, HomepageStyle } from './styles'
import ClientGrid from '../../components/ClientsGrid'

const SocialBar = () => (
    <StyledSocialBar offset={0}>
      <a aria-label="LinkedIn profile" href="https://www.linkedin.com/company/ambaumlabs/" target='_blank' rel="noopener noreferrer">
        <svg viewBox="0 0 448 512" height="32" width="32" focusable="false" role="img" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg" className="StyledIconBase-ea9ulj-0 duvCfz"><title>Linkedin
          icon</title>
          <path fill="currentColor"
  d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
        </svg>
      </a>
      <a aria-label="Facebook profile" href="https://facebook.com/ambaumlabs" target='_blank' rel="noopener noreferrer">
        <svg viewBox="0 0 20 20" height="32" width="32" focusable="false" role="img" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg" className="StyledIconBase-ea9ulj-0 duvCfz"><title>Facebook
          icon</title>
          <path
  d="M17 1H3c-1.1 0-2 .9-2 2v14c0 1.101.9 2 2 2h7v-7H8V9.525h2v-2.05c0-2.164 1.212-3.684 3.766-3.684l1.803.002v2.605h-1.197c-.994 0-1.372.746-1.372 1.438v1.69h2.568L15 12h-2v7h4c1.1 0 2-.899 2-2V3c0-1.1-.9-2-2-2z"/>
        </svg>
      </a>
      <a aria-label="Instagram profile" href="https://instagram.com/ambaumlabs" target='_blank' rel="noopener noreferrer">
        <svg viewBox="0 0 18 18" height="32" width="32" focusable="false" role="img" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg" className="StyledIconBase-ea9ulj-0 duvCfz">
          <title>Instagram icon</title>
          <path
  d="M17.946 5.29a6.606 6.606 0 00-.418-2.185 4.412 4.412 0 00-1.039-1.594A4.412 4.412 0 0014.895.472a6.606 6.606 0 00-2.184-.418C11.75.01 11.444 0 9 0S6.25.01 5.29.054a6.606 6.606 0 00-2.185.418A4.412 4.412 0 001.51 1.511 4.412 4.412 0 00.472 3.105a6.606 6.606 0 00-.418 2.184C.01 6.25 0 6.556 0 9s.01 2.75.054 3.71a6.606 6.606 0 00.418 2.185 4.412 4.412 0 001.039 1.594 4.411 4.411 0 001.594 1.039 6.606 6.606 0 002.184.418C6.25 17.99 6.556 18 9 18s2.75-.01 3.71-.054a6.606 6.606 0 002.185-.418 4.602 4.602 0 002.633-2.633 6.606 6.606 0 00.418-2.184C17.99 11.75 18 11.444 18 9s-.01-2.75-.054-3.71zm-1.62 7.347a4.978 4.978 0 01-.31 1.67 2.98 2.98 0 01-1.708 1.709 4.979 4.979 0 01-1.671.31c-.95.043-1.234.052-3.637.052s-2.688-.009-3.637-.052a4.979 4.979 0 01-1.67-.31 2.788 2.788 0 01-1.036-.673 2.788 2.788 0 01-.673-1.035 4.978 4.978 0 01-.31-1.671c-.043-.95-.052-1.234-.052-3.637s.009-2.688.052-3.637a4.979 4.979 0 01.31-1.67 2.788 2.788 0 01.673-1.036 2.788 2.788 0 011.035-.673 4.979 4.979 0 011.671-.31c.95-.043 1.234-.052 3.637-.052s2.688.009 3.637.052a4.979 4.979 0 011.67.31 2.788 2.788 0 011.036.673 2.788 2.788 0 01.673 1.035 4.979 4.979 0 01.31 1.671c.043.95.052 1.234.052 3.637s-.009 2.688-.052 3.637zM9 4.378A4.622 4.622 0 1013.622 9 4.622 4.622 0 009 4.378zM9 12a3 3 0 113-3 3 3 0 01-3 3zm5.884-7.804a1.08 1.08 0 11-1.08-1.08 1.08 1.08 0 011.08 1.08z"/>
        </svg>
      </a>
      <a aria-label="Twitter profile" href="https://twitter.com/ambaumlabs1" target="_blank" rel="noopener noreferrer">
        <svg viewBox="0 0 20 20" height="32" width="32" focusable="false" role="img" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg" className="StyledIconBase-ea9ulj-0 duvCfz"><title>Twitter
          icon</title>
          <path
  d="M17.316 6.246c.008.162.011.326.011.488 0 4.99-3.797 10.742-10.74 10.742-2.133 0-4.116-.625-5.787-1.697a7.577 7.577 0 005.588-1.562 3.779 3.779 0 01-3.526-2.621 3.858 3.858 0 001.705-.065 3.779 3.779 0 01-3.028-3.703v-.047a3.766 3.766 0 001.71.473 3.775 3.775 0 01-1.168-5.041 10.716 10.716 0 007.781 3.945 3.813 3.813 0 01-.097-.861 3.773 3.773 0 013.774-3.773 3.77 3.77 0 012.756 1.191 7.602 7.602 0 002.397-.916 3.789 3.789 0 01-1.66 2.088 7.55 7.55 0 002.168-.594 7.623 7.623 0 01-1.884 1.953z"/>
        </svg>
      </a>
    </StyledSocialBar>
) 

const LandingPage = ({ data }) => {
  const [honeypotTripped, tripHoneypot] = useState(false);

  const handleSubmit = (e) => {
    if (honeypotTripped) {
      e.preventDefault();
    } else {
      e.preventDefault();
      const el = e.target;
      const formData = new FormData();
 
      formData.append('name', el.querySelector('#contact-full-name').value);
      formData.append('email', el.querySelector('#contact-email').value);
      formData.append('company', el.querySelector('#contact-company').value);
      formData.append('growthReport', el.querySelector('#contact-growth-whitepaper').value);
      formData.append('meeting', el.querySelector('#contact-book-a-meeting').value);
      formData.append('message', el.querySelector('#contact-message').value);
      
      trackCustomEvent({
        category: "Form",
        action: "Submission",
        label: "Landing Page",
        value: 1
      });

      fetch("https://getform.io/f/885e17aa-6284-4fa3-9074-a9dc7fa27c04",
        {
          method: "POST",
          body: formData,
        }
      )
       .then(() => navigate('/thank-you'))
       .catch(error => console.log(error))
       }
  };

  return (
    <Layout>
        <Margin margin={64} />
        <Seo title={data.title} url={data.slug} />
        <Container style={{ padding: '0 32px' }}  fluid>
          <Row>
              <Col col={12}>
                  <Title>{data?.landing_page_fields?.title}</Title>
                  <SubTitle>{data?.landing_page_fields?.subtitle}</SubTitle>
              </Col>
          </Row>
        </Container>
        <Container>
            <ClientGrid maxLength={4} tilesPerRow={2} />
            <Margin margin={32}/>
            <Row>
                <Col col={12}>
                    <SubTitle>
                        {data?.landing_page_fields?.content}
                    </SubTitle>
                </Col>
            </Row>
            {data.landing_page_fields?.landingContentBlocks?.map(({
              title,
              content,
              image,
              linkLabel,
              linkUrl
            }, i) => (
              <Row key={`${title}-${i}`}>
                <Col col={12} md={6} mdOrder={i % 2 ? 2 : 1}>
                  <SquareImg>
                    <img src={image.mediaItemUrl} alt="thumbail" />
                  </SquareImg>
                </Col>
                <Col col={12} md={6} mdOrder={i % 2 ? 1 : 2}>
                  <TextBox to={linkUrl}>
                    <strong>{title}</strong>
                    <hr />
                    <p>
                      {parse(content)}
                    </p>
                    <CardFooter>
                      <span>{linkLabel}</span>
                      <svg viewBox="0 0 16 16" height="32" width="32" focusable="false" role="img" fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg" className="StyledIconBase-ea9ulj-0 duvCfz"><title>ArrowRightShort
                        icon</title>
                        <path fillRule="evenodd"
                d="M4 8a.5.5 0 01.5-.5h5.793L8.146 5.354a.5.5 0 11.708-.708l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L10.293 8.5H4.5A.5.5 0 014 8z"/>
                      </svg>
                    </CardFooter>
                  </TextBox>
                </Col>
                <Col col={12}>
                  <Margin margin={32}/>
                </Col>
              </Row>
            ))}
            {!data.landing_page_fields?.landingContentBlocks && data.contact_page_fields.contentBlocks.map(({
              title,
              content,
              image,
              linkLabel,
              linkUrl
            }, i) => (
              <Row key={`${title}-${i}`}>
                <Col col={12} md={6} mdOrder={i % 2 ? 2 : 1}>
                  <SquareImg>
                    <img src={image.mediaItemUrl} alt="thumbail" />
                  </SquareImg>
                </Col>
                <Col col={12} md={6} mdOrder={i % 2 ? 1 : 2}>
                  <TextBox to={linkUrl}>
                    <strong>{title}</strong>
                    <hr />
                    <p>
                      {parse(content)}
                    </p>
                    <CardFooter>
                      <span>{linkLabel}</span>
                      <svg viewBox="0 0 16 16" height="32" width="32" focusable="false" role="img" fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg" className="StyledIconBase-ea9ulj-0 duvCfz"><title>ArrowRightShort
                        icon</title>
                        <path fillRule="evenodd"
                d="M4 8a.5.5 0 01.5-.5h5.793L8.146 5.354a.5.5 0 11.708-.708l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L10.293 8.5H4.5A.5.5 0 014 8z"/>
                      </svg>
                    </CardFooter>
                  </TextBox>
                </Col>
                <Col col={12}>
                  <Margin margin={32}/>
                </Col>
              </Row>
            ))}
            <HomepageStyle>
              <span className="container">
                <span className="section__title">{data.landing_page_fields?.faq.header}</span>
              </span>
            </HomepageStyle>
            <Margin margin={32}/>
            {data.landing_page_fields?.faq.content.map(({ qa: { question, answer }}) => (
              <div key={question}>
                <Content align="left">{question}</Content>
                <p>{answer}</p>
              </div>
            ))}
            <Row>
                <Col col={12}>
                  <Margin margin={64}/>
                  <Form
                    onSubmit={handleSubmit}
                    id="contact"  
                  >
                    <Row>
                    <Col col={12}>
                        <H1Span><strong>Let’s talk</strong></H1Span>
                    </Col>
                    <Col col={12}>
                        <Content>We can help you scale your digital business.</Content>
                    </Col>
                    <Col col={12}>
                        <label htmlFor="contact-full-name" style={{ position: 'absolute', fontSize: '0px' }}>Name *</label>
                        <input required placeholder="Name*" id="contact-full-name" name="fullname" type="text" style={{ backgroundImage: 'url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAfBJREFUWAntVk1OwkAUZkoDKza4Utm61iP0AqyIDXahN2BjwiHYGU+gizap4QDuegWN7lyCbMSlCQjU7yO0TOlAi6GwgJc0fT/fzPfmzet0crmD7HsFBAvQbrcrw+Gw5fu+AfOYvgylJ4TwCoVCs1ardYTruqfj8fgV5OUMSVVT93VdP9dAzpVvm5wJHZFbg2LQ2pEYOlZ/oiDvwNcsFoseY4PBwMCrhaeCJyKWZU37KOJcYdi27QdhcuuBIb073BvTNL8ln4NeeR6NRi/wxZKQcGurQs5oNhqLshzVTMBewW/LMU3TTNlO0ieTiStjYhUIyi6DAp0xbEdgTt+LE0aCKQw24U4llsCs4ZRJrYopB6RwqnpA1YQ5NGFZ1YQ41Z5S8IQQdP5laEBRJcD4Vj5DEsW2gE6s6g3d/YP/g+BDnT7GNi2qCjTwGd6riBzHaaCEd3Js01vwCPIbmWBRx1nwAN/1ov+/drgFWIlfKpVukyYihtgkXNp4mABK+1GtVr+SBhJDbBIubVw+Cd/TDgKO2DPiN3YUo6y/nDCNEIsqTKH1en2tcwA9FKEItyDi3aIh8Gl1sRrVnSDzNFDJT1bAy5xpOYGn5fP5JuL95ZjMIn1ya7j5dPGfv0A5eAnpZUY3n5jXcoec5J67D9q+VuAPM47D3XaSeL4AAAAASUVORK5CYII=&quot;)', backgroundRepeat: 'no-repeat', backgroundAttachment: 'scroll', backgroundSize: '16px 18px', backgroundPosition: '98% 50%', cursor: 'auto' }}/>
                    </Col>
                    <Col col={6}>
                        <label htmlFor="contact-email" style={{ position: 'absolute',  fontSize: '0px' }}>
                        Email *
                        </label>
                        <input required placeholder="Email*" id="contact-email" type="text" name="email"/>
                    </Col>
                    <Col col={6}>
                        <label htmlFor="contact-company" style={{ position: 'absolute',  fontSize: '0px' }}>Company</label>
                        <input id="contact-company" type="text" name="company" placeholder="Company Name (optional)"/>
                    </Col>
                    <Col col={6}>
                        <label htmlFor="contact-url" style={{ position: 'absolute',  fontSize: '0px' }}>Store URL</label>
                        <input id="contact-url" type="text" name="url" placeholder="Current Store URL (optional)"/>
                    </Col>
                    <Col col={6}>
                        <label htmlFor="contact-phone" style={{ position: 'absolute',  fontSize: '0px' }}>Phone</label>
                        <input id="contact-phone" type="telephone" name="phone" placeholder="Phone Number (optional)"/>
                    </Col>
                    <Col col={6}>
                        <label htmlFor="contact-growth-whitepaper">Get our 3 Pillars for Growth Report?</label>
                        <select id="contact-growth-whitepaper" name="growth-report" defaultValue="yes">
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                    </Col>
                    <Col col={6}>
                        <label htmlFor="contact-book-a-meeting">Book a meeting?</label>
                        <select id="contact-book-a-meeting" name="book-a-meeting" defaultValue="yes">
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                    </Col>
                    <Col col={12}>
                        <label htmlFor="contact-message">Your Message * </label>
                        <textarea required id="contact-message" name="message" rows="5" placeholder="Get our Growth and Automation Guide"></textarea>
                      </Col>
                      <Col col={12}>
                        {/* honeypot to decrease spam submissions */}
                        <input
                          onChange={() => tripHoneypot(true)}
                          type="checkbox"
                          name="contact_me_by_fax_only"
                          value="0"
                          tabIndex="-1"
                          autoComplete="off"
                          style={{ display: 'none' }}
                        />
                        <button type="submit">Submit</button>
                      </Col>
                    </Row>
                  <SocialBar />
                </Form>
              </Col>
          </Row>
        </Container>
      <Margin margin={64} />
    </Layout>
  )
}

export default LandingPage
