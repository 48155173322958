import React from "react";
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import parse, { domToReact } from "html-react-parser"
import { Container, Col, Row } from "styled-bootstrap-grid"
import ContactBlock from "../../components/Contact"
import { Link } from "gatsby"
import {
  ContentWrap,
  HeroBlock,
  ServicesTilesWrap,
  StyledServicesTemplate,
  YellowBorderWrap,
  FlexRow,
  OurWork,
  TileSubheading,
  TileHeading,
  FixedContent,
} from "./styles"
import ClientsRow from "../../components/ClientsRow"

const ServicesPageTemplate = ({ data }) => {
  const { ACFServicesTemplateOptions: { tileGrid } } = data;

  const body = [];
  const h2 = [];
  const h1 = [];

  const parseContent = (input) => {
    if (input?.name === 'h1') {
      h1.push(input)
    }
    if (input?.name === 'h2') {
      h2.push(input)
    }
    if (input?.name === 'p'
      || input?.name === 'ul'
      || input?.name === 'table'
      || input?.name === 'h3'
      || input?.name === 'h4'
      || input?.name === 'h5'
      || input?.name === 'h6'
    ) {
      body.push(input)
    }
    if (input?.attribs?.class?.includes("gatsby-image-wrapper")) {
      body.push(input)
    }
  }

  parse(data?.content, { replace: node => parseContent(node) });
  return (
    <Layout>
      <Seo title={data?.title ?? 'Page'} url={data.slug}/>
      <StyledServicesTemplate>
        <HeroBlock>
          <img src={data?.featuredImage?.node?.localFile?.publicURL} alt="" />
          <FixedContent>
            <Container>
              <Row>
                <Col col={12} md={8}>
                  <YellowBorderWrap>
                    {domToReact(h1)}
                    {domToReact(h2)}
                  </YellowBorderWrap>
                </Col>
              </Row>
            </Container>
          </FixedContent>
        </HeroBlock>

        <Container>
          <Row>
            <Col col={12}>
              <ContentWrap>
                {domToReact(body)}
              </ContentWrap>
            </Col>
          </Row>
        </Container>
        <ServicesTilesWrap fluid>
          <Row>
            <Col col={12}>
              {data.ACFServicesTemplateOptions.tileGridHeading && (
                <TileHeading>
                  {data.ACFServicesTemplateOptions.tileGridHeading}
                </TileHeading>
              )}
              {data.ACFServicesTemplateOptions.tileGridSubheading && (
                <TileSubheading>
                  {data.ACFServicesTemplateOptions.tileGridSubheading}
                </TileSubheading>
              )}
            </Col>
          </Row>
          {tileGrid?.length > 0 && (
            <Row>
              <Col col={12}>
                <FlexRow center>
                  {tileGrid?.map(({ tile, tileUrl: { slug } }, i) => {
                    return (
                      <Link to={`/${slug}`} key={i}>
                        {parse(tile)}
                      </Link>
                    )
                  })}
                </FlexRow>
              </Col>
            </Row>
          )}
        </ServicesTilesWrap>
      </StyledServicesTemplate>
      <Container>
        <Row>
          <Col col={12}>
            <OurWork>
              {data?.ACFServicesTemplateOptions?.ourWorkHeading && <strong>{data.ACFServicesTemplateOptions.ourWorkHeading}</strong>}
              {data?.ACFServicesTemplateOptions?.ourWorkSubheading && <p>{data.ACFServicesTemplateOptions.ourWorkSubheading}</p>}
              <ClientsRow />
            </OurWork>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col col={12} noGutter>
            <ContactBlock />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default ServicesPageTemplate;
