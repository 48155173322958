import React from "react"
import { StyledFeaturedVerticals } from "./styles"
import { SectionPadding } from "../../sharedStyles"
import { Container, Col, Row } from "styled-bootstrap-grid"
import Card from "../../../../components/Card"
import { domToReact } from "html-react-parser"

const FeaturedVerticals = ({ title, description, verticals }) => {
  let verticalDetails, isSvg;
  if (verticals[0]?.image) {
    isSvg = false;
    verticalDetails = verticals.map(({ image, title, description, link }) => ({
      icon: image.mediaItemUrl,
      heading: title,
      description,
      url: link.slug,
    }));
  } else {
    isSvg = true;
    verticalDetails = verticals.filter(({ children }) => Boolean(children)).map((vertical) => {
      const img = vertical.children
        ?.find(({ attribs }) => attribs?.class === "card__img-wrapper")
        ?.children;
      const content = vertical.children?.find(({ attribs }) => attribs?.class === "card__content");
      const title = content?.children?.find(({ attribs }) => attribs?.class === "h5 card__title");
      const description = content?.children?.find(({ attribs }) => attribs?.class === "card__description");
      const link = content?.children?.find(({ attribs }) => attribs?.class === "card__link");

      return {
        icon: domToReact(img),
        heading: domToReact(title?.children ?? ''),
        description: domToReact(description?.children ?? ''),
        url: link?.attribs?.href?.replace(/https:\/\/ambaum.com\/?/, '').replace(/^\//, ''),
      };
    });
  }

  return (
    <StyledFeaturedVerticals>
      <div className="container">
        <Container>
          <Row className="justify-content-center">
            <Col col={12}>
              <h2 className="section__title show">{title}</h2>
              {description && <div className="section__description show">{description}</div>}
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row className="justify-content-center">
            {verticalDetails
              .map(({ icon, heading, description, url }, i) => (
                <Col col={12} md={4} lg={3} key={i}>
                  <Card
                    excerpt={description}
                    link={url}
                    img={icon}
                    fullImg={false}
                    natural={true}
                    truncate={false}
                    title={heading}
                    svg={isSvg}
                  />
                </Col>
              ))}
          </Row>
        </Container>
      </div>
      <SectionPadding />
    </StyledFeaturedVerticals>
  )
}

export default FeaturedVerticals
