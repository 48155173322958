import styled from "styled-components"
import { HomepageStyles } from "../../sharedStyles"

export const StyledFeaturedVerticals = styled.section`
  ${HomepageStyles}
  max-width: 100%;
  padding: 0 16px;
  > .container .section__title,
  > .container .section__description {
    display: none;
    &.show {
      display: inline-block;
    }
  }
  .featured-posts-grid__list {
    display: none;
  }
  .justify-content-center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .justify-content-start {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .justify-content-end {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
`;
