import styled from "styled-components"
import { Link } from "gatsby"

export const StyledCard = styled(Link)`
  color: #414141;
  text-decoration: none;
  border-radius: 8px;
  border: 1px solid #dadce0;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  height: calc(100% - 16px);
  margin: 16px 0;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  svg {
    color: #000;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  @media (min-width: 768px) {
    &:hover {
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
      svg {
        color: rgb(71, 159, 219);
      }
    }
  }
`

export const Margin = styled.div`
  padding: 8px;
`

const contentStyles = `
  padding: 0 40px;
  margin-bottom: 6px;
`

export const CardHeader = styled.div`
  ${contentStyles}
  strong {
    text-transform: uppercase;
    font-size: 22px;
    line-height: 1px;
    letter-spacing: 1.5px;
    font-weight: 500;
  }
`

export const ImgWrap = styled.div`
  padding: ${({ fullImg }) => fullImg ? '0' : '40px'};
  margin-bottom: ${({ fullImg }) => fullImg ? '34px' : '0'};
`

export const CardContent = styled.div`
  ${contentStyles}
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin: auto;
  margin-left: ${({ fullImg }) => fullImg ? 'auto' : '0'};
`

export const CardFooter = styled.div`
  ${contentStyles}
  padding: 0 60px 40px 40px;
  margin-top: 16px;
  display: flex;
  justify-content: ${({ justify }) => justify ?? 'space-between'};
  align-items: center;
`
