import styled from 'styled-components';
import { Container, media } from "styled-bootstrap-grid"

export const StyledServicesTemplate = styled.div`
  h1 {
    font-size: 42px;
    line-height: 1;
    padding-bottom: 16px;
    ${media.md`
        font-size: 50px;
        padding-bottom: 8px;
    `};
  }
  h2 {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2;
    ${media.md`
        font-size: 22px;
    `};
  }
  p {
    margin: 32px 0;
    line-height: 1.5;
    letter-spacing: 0.5px;
  }
  background-color: white;
`;

export const HeroBlock = styled.div`
  position: relative;
  padding: 0 0 32px 0;
  background: #f3f3f3;
  display:flex;
  width:100%;
  height: 475px;
  ${media.md`
    padding: 0;
  `}
  img {
    object-fit: cover;
    width: 100%;
  }
  h1 {
    margin-bottom: 0;
    padding-bottom: 8px;
    font-size: 42px;
    ${(props) => props.hasBackground ? 'text-shadow: 1px 1px 0px #333;' : ''}
    ${media.md`
      font-size: 50px;
    `}
  }
  h2 {
    letter-spacing: 1px;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 400;
    line-height: 1.2;
    ${(props) => props.hasBackground ? 'text-shadow: 1px 1px 0px #333;' : ''}
    ${media.md`
      font-size: 22px;
    `}
  }
  .gatsby-image-wrapper {
    object-fit: cover;
    position: absolute !important;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    ${media.md`
      position: relative !important;
    `}
  }
`;

export const FixedContent = styled.div`
padding: 0px;
    position: absolute;
    padding: 0px;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    >div >div >div >div {
      margin: 0;
    }
`

export const YellowBorderWrap = styled.div`
  border-left: 5px solid #FFC600;
  padding-left: 1.25rem;
  margin: 0;
  h1{
    margin: 0 0 20px 0px;
  }
  h2 {
    font-weight: 400;
    font-size: 22px;
  }
  .has-white-color {
    color: #fff;
    text-shadow: 1px 1px 0px #333;
  }
  ${({ missingHero }) => missingHero && `
    h1 {
      color: hsla(0, 0%, 0%, 0.8) !important;
    }
  `}
  ${media.md`
    margin: auto 112px;
  `}
`;

export const ContentWrap = styled.div`
  margin: 0 auto 24px;
  line-height: 1.5;
  font-size: 18px;
  .has-large-font-size {
    font-size: 2em;
  }
  h1, h2, h3 {
    display: block;
    margin: 0;
    padding: 24px 0 5px;
  }
  p {
    margin: 0;
    padding: 8px 0;
  }
  ul, ol {
    list-style-position: outside;
    margin-left: 50px;
    padding: 15px;
  }
  p + h3,
  p + h4,
  p + h5,
  p + h6,
  ul + h3,
  ul + h4,
  ul + h5,
  ul + h6,
  ol + h3,
  ol + h4,
  ol + h5,
  ol + h6,
  blockquote + h3,
  blockquote + h4,
  blockquote + h5,
  blockquote + h6 {
    margin-top: 20px;
  }
  h3, h4, h5, h6 {
    blockquote + & {
    }
  }
  ${media.md`
    padding: 32px 100px;
  `}
  .gatsby-image-wrapper {
    display: block !important;
    margin: 1.45rem auto;
    img {
      object-fit: scale-down !important;
    }
  }
  figure{
    padding: 25px 0;
  }
  .aligncenter{

    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

  }
  .alignleft{
    margin-left:0;
    margin-right:auto;
    display: flex;
    justify-content:center;
    align-items: center;
    flex-wrap:wrap;
    ${media.md`
      flex-wrap:nowrap;
      justify-content:flex-start;
    `}
  }
  .alignright{
    margin-left:auto;
    margin-right:0;
    display: flex;
    justify-content: center;

    align-items: center;
    flex-direction: row-reverse;
    flex-wrap:wrap;
    ${media.md`
    justify-content:flex-end;
      flex-wrap:nowrap;
    `}
    figcaption{
      text-align:center;
      ${media.md`
        text-align:right;
      `}
    }
  }
  .has-text-align-center{
    text-align: center;
  }
  .has-text-align-right{
    text-align: right;
  }
  .has-text-align-left{
    text-align: left;
  }
  figcaption {
    margin: 1em;
  }

  .is-layout-flex {
    display: flex;
    margin: 20px 0;
  }

  .is-content-justification-center {
    justify-content: center;
  }

  .wp-block-button__link {
    border-radius: 9999px;
    box-shadow: none;
    text-decoration: none;
    padding: calc(0.667em + 2px) calc(1.333em + 2px);
    font-size: 1.125em;
  }

  .has-luminous-vivid-amber-background-color {
    background-color: #fcb900;
  }

  .has-black-color {
    color: #000;
  }
`

export const ServicesTilesWrap = styled(Container)`
  padding: 42px 0;
  background-color: #f9f9f9;
  strong {
    display: block;
  }
  ${media.md`
    margin-top: 84px;
    padding: 0;
  `}
`

export const FlexRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: ${props => (props.center ? "center" : "flex-start")};
  margin-bottom: 70px;
  > a {
    color: #1e1e1e;
    text-decoration: none;
    padding: 16px 42px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    transition: 0.2s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
    > div {
      margin: 0;
      padding: 12px;
      margin-bottom: 8px;
      border-width: 4px;
    }
    strong {
      font-size: 18px;
      font-weight: 700;
      padding: 0;
      border-left: 5px solid #FFC600;
      padding-left: 1.25rem;
    }
    p, ul {
      margin: 0;
      padding-top: 12px;
      font-size: 14px;
      line-height: 1.5;
      font-weight: 300;
    }
    ul {
      display: block;
      padding-left: 42px;
    }
    ${media.md`
      padding: 0 32px;
      width: 50%;
      strong {
        font-size: 22px;
      }
      p {
        font-size: 16px;
      }
    `}
    ${media.lg`
      width: calc(100% / 3)
    `}
    ${media.xl`
      width: 25%;
    `}
  }
`

export const OurWork = styled.div`
  padding: 0;
  text-align: center;
  line-height: 1;
  margin-bottom: 70px;
  > strong {
    font-weight: 500;
    font-size: 42px;
    padding-bottom: 24px;
    display: block;
    margin-top: 140px;
    border-left: 5px solid #FFC600;
    padding: 16px;
    padding-left: 1.25rem;
    text-align: left;
    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: inherit;
      text-decoration: none;
      transition: 0.2s;
      svg {
        transition: 0.2s;
      }
      &:hover {
        color: #489FDB;
        fill: #489FDB;
        svg {
          transform: scale(1.1);
        }
      }
    }
  }
  > p {
    margin: 0;
    font-size: 16px;
    margin-bottom: 70px;
  }
  ${media.md`
    > strong {
      font-size: 50px;
    }
    > p {
      font-size: 22px;
    }
  `}
`;

export const TileHeading = styled.strong`
  font-size: 42px;
  font-weight: 500;
  text-align: center;
  line-height: 1;
  padding-bottom: 24px;
  margin-top: 70px;
  ${media.md`
    font-size: 50px;
  `}
`;

export const TileSubheading = styled.span`
  text-align: center;
  font-size: 16px;
  display: block;
  line-height: 1;
  margin-bottom: 70px;
  ${media.md`
    font-size: 22px;
  `}
`;
