import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import selectArrow from '../../../static/down-chevron.svg'
import { HomepageStyles } from '../Homepage/sharedStyles';

const sharedStyles = `
    text-align: center;
`

export const Title = styled.h1`
    ${sharedStyles}
    font-size: 50px;
    ${media.md `
        font-size: 72px;
        padding: 16px 32px;
        letter-spacing: 3.35px;
    `}
`

export const SubTitle = styled.h2`
    ${sharedStyles}
    font-size: 22px;
    font-weight: 300;
    ${media.md `
        font-size: 30px;
        letter-sapcing: -1.2px;
    `}
`

export const Content = styled.p`
    ${sharedStyles}
    font-size: 22px;
    font-weight: 300;
    ${({ align }) => align && `text-align: ${align};`}
    ${media.md `
        font-size: 30px;
        padding: 16px 64px;
        ${({ align }) => align && `padding: 16px 0;`}
    `}
`

export const HomepageStyle = styled.div`
    ${HomepageStyles}   
    .section__title {
        margin-top: 64px !important;
        padding-bottom: 0 !important;
    }
`

export const H1Span = styled.span`
    ${sharedStyles}
    display: block;
    font-size: 50px;
    line-height: 1;
    ${media.md `
        font-size: 72px;
    `}
`

export const Form = styled.form`
    font-weight: 300;
    background-color: #F9F9F9;
    padding: 32px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    ${media.md `
        max-width: 80%;
        margin: auto;
    `}
    input, textarea, select {
        width: 100%;
        padding: 16px;
        margin: 8px 4px;
        border: 1px solid #1e1e1e;
        border-radius: 0;
    }
    select {
        appearance: none;
        background-image: url(${selectArrow});
        background-position: top 50% right 5px;
        background-repeat: no-repeat;
        background-size: 24px;
        padding: 18px 16px;
        width: 100%;
    }
    button {
        border: 0;
        background-color: #479FDB;
        transition: 0.2s;
        color: white;
        font-weight: bold;
        font-size: 22px;
        padding: 16px 64px;
        cursor: pointer;
        margin: auto auto 32px;
        display: block;
        &:hover {
            background-color: #206ea2;
        }
        ${media.md`
            margin-left: 0;
        `}
    }
    label {
        display: block;
        font-size: 18px;
        margin: 8px 0 0 8px;
        font-weight: 500;
    }
`