import React from "react"
import {
  Tags,
  StyledClientRow,
  StyledClientTile,
  CardFooter,
  LogoWrap,
  LinkWrap
} from "./styles"
import parse from 'html-react-parser'
import { graphql, useStaticQuery, Link } from "gatsby"

const ClientsRow = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpPost(filter: {ACFCustomPostType: {isCustomPostType: {eq: "Client Row"}}}) {
        edges {
          node {
            title
            id
            slug
            excerpt
            acfCustomPostFields {
              backgroundColor
              order
              logo {
                title
                localFile {
                  publicURL
                }
              }
            }
            featuredImage {
              node {
                title
                localFile {
                  publicURL
                }
              }
            }
            tags {
              nodes {
                id
                name
                slug
              }
            }
          }
        }
      }
    }
  `)
  const tiles = data?.allWpPost?.edges?.sort((a, b) => a.node?.acfCustomPostFields?.order - b.node?.acfCustomPostFields?.order);
  return (
    <>
    <StyledClientRow>
      {tiles.map(({ node }) => (
        <li key={node.id}>
          <StyledClientTile
            to={`/${node.slug}/`}
          >
            <LogoWrap>
              <img
                alt={node?.acfCustomPostFields?.logo?.title}
                src={node?.acfCustomPostFields?.logo?.localFile?.publicURL}
              />
            </LogoWrap>
            <Tags
              className="tags"
              bg={node?.acfCustomPostFields?.backgroundColor}
            >
              <ul>
                {node?.tags?.nodes?.map((tag) => (
                  <li key={tag?.id}>
                    {/*<Link to={`/${tag?.slug}/`}>*/}
                      {tag?.name}
                    {/*</Link>*/}
                  </li>
                ))}
              </ul>
            </Tags>
            <img
              alt={node?.featuredImage?.node?.title}
              src={node?.featuredImage?.node?.localFile?.publicURL}
            />
            <CardFooter
              bg={node?.acfCustomPostFields?.backgroundColor}
            >
              {parse(node?.excerpt)}
              <svg width="20" height="12" viewBox="0 0 20 12" fill="none">
                <path d="M19.5303 6.56451C19.8232 6.27162 19.8232 5.79674 19.5303 5.50385L14.7574 0.730879C14.4645 0.437985 13.9896 0.437985 13.6967 0.730879C13.4038 1.02377 13.4038 1.49865 13.6967 1.79154L17.9393 6.03418L13.6967 10.2768C13.4038 10.5697 13.4038 11.0446 13.6967 11.3375C13.9896 11.6304 14.4645 11.6304 14.7574 11.3375L19.5303 6.56451ZM0 6.78418H19V5.28418H0L0 6.78418Z" fill="white"/>
              </svg>
            </CardFooter>
          </StyledClientTile>
        </li>
      ))}
    </StyledClientRow>
    <LinkWrap>
      <Link to='/our-work'>
        View All Work
        &nbsp;
        <svg width="20" height="12" viewBox="0 0 20 12" fill="currentColor">
          <path d="M19.5303 6.56451C19.8232 6.27162 19.8232 5.79674 19.5303 5.50385L14.7574 0.730879C14.4645 0.437985 13.9896 0.437985 13.6967 0.730879C13.4038 1.02377 13.4038 1.49865 13.6967 1.79154L17.9393 6.03418L13.6967 10.2768C13.4038 10.5697 13.4038 11.0446 13.6967 11.3375C13.9896 11.6304 14.4645 11.6304 14.7574 11.3375L19.5303 6.56451ZM0 6.78418H19V5.28418H0L0 6.78418Z" fill="currentColor"/>
        </svg>
      </Link>
    </LinkWrap>
    </>
  )
}

export default ClientsRow