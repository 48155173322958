import React from "react"
import parse from 'html-react-parser';
import {
  CardContent,
  CardFooter,
  CardHeader,
  ImgWrap,
  StyledCard
} from "./styles"

const Card = ({
  link,
  img,
  fullImg = true,
  excerpt,
  truncate = true,
  date = null,
  alt = '',
  svg = false,
  natural = false,
  title
}) => (
  <>
    <StyledCard to={`/${link}`}>
      <ImgWrap className="img-wrap" fullImg={fullImg}>
        {img && !svg && <img src={img} alt={alt} style={{ display: 'block', maxWidth: fullImg ? '100%' : `${natural ? '' : '120px'}` }} />}
        {img && svg && img}
      </ImgWrap>
      {title && (
        <CardHeader>
          <strong>
            {title}
          </strong>
        </CardHeader>
      )}
      <CardContent fullImg={fullImg}>
        {truncate && excerpt && (
          parse(excerpt.replace(/<p>/g, "").replace(/<\/p>/g,"").slice(0, 70))
        )}
        {truncate && excerpt && excerpt.replace(/<p>/g, "").replace(/<\/p>/g,"").length > 70 && "..."}
        {!truncate && (
          parse(excerpt)
        )}
      </CardContent>
      <CardFooter justify={date ? 'space-between' : 'flex-start'}>
        {date ? (
          <span>{date}</span>
        ) : (
          <span>Learn more</span>
        )}
        <svg viewBox="0 0 16 16" height="32" width="32" focusable="false" role="img" fill="currentColor"
             xmlns="http://www.w3.org/2000/svg" className="StyledIconBase-ea9ulj-0 duvCfz"><title>ArrowRightShort
          icon</title>
          <path fillRule="evenodd"
  d="M4 8a.5.5 0 01.5-.5h5.793L8.146 5.354a.5.5 0 11.708-.708l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L10.293 8.5H4.5A.5.5 0 014 8z"/>
        </svg>
      </CardFooter>
    </StyledCard>
  </>
)

export default Card
