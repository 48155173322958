import React from 'react'
import { StyledAboutTemplate, EmployeesGrid, EmployeeTile } from "./styles"
import Seo from "../../components/seo"
import { ContentWrap, FixedContent, HeroBlock, YellowBorderWrap } from "../Services/styles"
import { Col, Container, Row } from "styled-bootstrap-grid"
import parse, { domToReact } from "html-react-parser"
import ContactBlock from "../../components/Contact"
import Layout from "../../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import FeaturedVerticals from '../Homepage/components/FeaturedVerticals'

const AboutTemplate = ({ data }) => {
  const employeesData = useStaticQuery(graphql`
    query {
      allWpPost(filter: {ACFCustomPostType: {isCustomPostType: {eq: "Employee"}}}) {
        nodes {
          title
          acfCustomPostFields {
            order
          }
          Employee {
            headshot {
              localFile {
                publicURL
              }
            }
            jobTitle
            linkedin
          }
          excerpt
          id
        }
      }
    }
  `)
  const body = [];
  const h2 = [];
  const h1 = [];

  const parseContent = (input) => {
    if (input?.name === 'h1') {
      h1.push(input)
    }
    if (input?.name === 'h2') {
      h2.push(input)
    }
    if (input?.name === 'p') {
      body.push(input)
    }
  }
  const employees = employeesData.allWpPost.nodes;
  parse(data?.content, { replace: node => parseContent(node) });
  const { featuredVerticalsTitle, featuredVerticalsDescription, featuredVerticals } = data?.ACFFeaturedTestimonialsCards ?? {};
  return (
    <Layout>
      <Seo title={data?.title ?? 'Page'} url={data.slug}/>
      <StyledAboutTemplate>
        <HeroBlock>
          <img src={ data?.featuredImage?.node?.localFile?.publicURL } alt="" />
          <FixedContent>
            <Row>
              <Col col={12}>
                {h1.length ? domToReact(h1) : <h1 style={{ color: '#ffffff' }}>{data?.title}</h1>}
                {domToReact(h2)}
              </Col>
            </Row>
          </FixedContent>
        </HeroBlock>
        <Container>
          <Row>
            <Col col={12}>
              <ContentWrap>
                {domToReact(body)}
              </ContentWrap>
            </Col>
          </Row>
        </Container>
        <section style={{ backgroundColor: '#F9F9F9' }}>
          <Container>
            <Row>
              <Col col={12}>
                <YellowBorderWrap style={{ margin: '80px 0 48px' }}>
                  <h1 style={{ fontWeight: 400 }}>Ambaum Leadership</h1>
                  <div style={{ fontSize: '22px', width: '100%', maxWidth: '935px' }}>We are a boutique Shopify development partner made up of technologists, developers, project managers, and business leaders that have sat in your seat and understand what it means to scale an eCommerce business.</div>
                </YellowBorderWrap>
                <EmployeesGrid>
                  {employees?.sort((a, b) => a?.acfCustomPostFields?.order - b?.acfCustomPostFields?.order).map(({
                    id,
                    title,
                    excerpt,
                    Employee: {
                      headshot,
                      jobTitle,
                      linkedin,
                    }
                  }) => (
                    <EmployeeTile key={id}>
                      <span>
                        <img src={headshot?.localFile?.publicURL} alt={title} />
                        <strong>{title}</strong>
                        <div>{jobTitle}</div>
                        {parse(excerpt)}
                        {linkedin && (
                          <a aria-label="LinkedIn profile" href={linkedin} target="_blank" rel="noopener noreferrer">
                            <svg viewBox="0 0 24 24" height="48" width="48" focusable="false" role="img"
                                 fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                 className="StyledIconBase-ea9ulj-0 duvCfz"><title>Linkedin icon</title>
                              <circle cx="4.983" cy="5.009" r="2.188"/>
                              <path
  d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"/>
                            </svg>
                          </a>
                        )}
                      </span>
                    </EmployeeTile>
                  ))}
                </EmployeesGrid>
              </Col>
            </Row>
          </Container>
        </section>
        <Row>
          <Col col={12}>
            <FeaturedVerticals
              title={featuredVerticalsTitle}
              description={featuredVerticalsDescription}
              verticals={featuredVerticals}
            />
          </Col>
        </Row>
      </StyledAboutTemplate>
      <Container fluid>
        <Row>
          <Col col={12} noGutter>
            <ContactBlock />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default AboutTemplate
