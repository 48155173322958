import React from 'react'
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import { Container, Col, Row } from "styled-bootstrap-grid"
import parse, { domToReact } from 'html-react-parser'
import {
  StyledContactPage,
  LogoBar,
  Margin,
  StyledSocialBar
} from "./styles"
import ClientsRow from '../../components/ClientsRow'
import { OurWork } from '../Services/styles'

const SocialBar = () => (
  <StyledSocialBar>
    <a aria-label="LinkedIn profile" href="https://www.linkedin.com/company/ambaumlabs/" target='_blank' rel="noopener noreferrer">
      <svg viewBox="0 0 448 512" height="32" width="32" focusable="false" role="img" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg" className="StyledIconBase-ea9ulj-0 duvCfz"><title>Linkedin
        icon</title>
        <path fill="currentColor"
d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
      </svg>
    </a>
    <a aria-label="Facebook profile" href="https://facebook.com/ambaumlabs" target='_blank' rel="noopener noreferrer">
      <svg viewBox="0 0 20 20" height="32" width="32" focusable="false" role="img" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg" className="StyledIconBase-ea9ulj-0 duvCfz"><title>Facebook
        icon</title>
        <path
d="M17 1H3c-1.1 0-2 .9-2 2v14c0 1.101.9 2 2 2h7v-7H8V9.525h2v-2.05c0-2.164 1.212-3.684 3.766-3.684l1.803.002v2.605h-1.197c-.994 0-1.372.746-1.372 1.438v1.69h2.568L15 12h-2v7h4c1.1 0 2-.899 2-2V3c0-1.1-.9-2-2-2z"/>
      </svg>
    </a>
    <a aria-label="Instagram profile" href="https://instagram.com/ambaumlabs" target='_blank' rel="noopener noreferrer">
      <svg viewBox="0 0 18 18" height="32" width="32" focusable="false" role="img" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg" className="StyledIconBase-ea9ulj-0 duvCfz">
        <title>Instagram icon</title>
        <path
d="M17.946 5.29a6.606 6.606 0 00-.418-2.185 4.412 4.412 0 00-1.039-1.594A4.412 4.412 0 0014.895.472a6.606 6.606 0 00-2.184-.418C11.75.01 11.444 0 9 0S6.25.01 5.29.054a6.606 6.606 0 00-2.185.418A4.412 4.412 0 001.51 1.511 4.412 4.412 0 00.472 3.105a6.606 6.606 0 00-.418 2.184C.01 6.25 0 6.556 0 9s.01 2.75.054 3.71a6.606 6.606 0 00.418 2.185 4.412 4.412 0 001.039 1.594 4.411 4.411 0 001.594 1.039 6.606 6.606 0 002.184.418C6.25 17.99 6.556 18 9 18s2.75-.01 3.71-.054a6.606 6.606 0 002.185-.418 4.602 4.602 0 002.633-2.633 6.606 6.606 0 00.418-2.184C17.99 11.75 18 11.444 18 9s-.01-2.75-.054-3.71zm-1.62 7.347a4.978 4.978 0 01-.31 1.67 2.98 2.98 0 01-1.708 1.709 4.979 4.979 0 01-1.671.31c-.95.043-1.234.052-3.637.052s-2.688-.009-3.637-.052a4.979 4.979 0 01-1.67-.31 2.788 2.788 0 01-1.036-.673 2.788 2.788 0 01-.673-1.035 4.978 4.978 0 01-.31-1.671c-.043-.95-.052-1.234-.052-3.637s.009-2.688.052-3.637a4.979 4.979 0 01.31-1.67 2.788 2.788 0 01.673-1.036 2.788 2.788 0 011.035-.673 4.979 4.979 0 011.671-.31c.95-.043 1.234-.052 3.637-.052s2.688.009 3.637.052a4.979 4.979 0 011.67.31 2.788 2.788 0 011.036.673 2.788 2.788 0 01.673 1.035 4.979 4.979 0 01.31 1.671c.043.95.052 1.234.052 3.637s-.009 2.688-.052 3.637zM9 4.378A4.622 4.622 0 1013.622 9 4.622 4.622 0 009 4.378zM9 12a3 3 0 113-3 3 3 0 01-3 3zm5.884-7.804a1.08 1.08 0 11-1.08-1.08 1.08 1.08 0 011.08 1.08z"/>
      </svg>
    </a>
    <a aria-label="Twitter profile" href="https://twitter.com/ambaumlabs1" target="_blank" rel="noopener noreferrer">
      <svg viewBox="0 0 20 20" height="32" width="32" focusable="false" role="img" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg" className="StyledIconBase-ea9ulj-0 duvCfz"><title>Twitter
        icon</title>
        <path
d="M17.316 6.246c.008.162.011.326.011.488 0 4.99-3.797 10.742-10.74 10.742-2.133 0-4.116-.625-5.787-1.697a7.577 7.577 0 005.588-1.562 3.779 3.779 0 01-3.526-2.621 3.858 3.858 0 001.705-.065 3.779 3.779 0 01-3.028-3.703v-.047a3.766 3.766 0 001.71.473 3.775 3.775 0 01-1.168-5.041 10.716 10.716 0 007.781 3.945 3.813 3.813 0 01-.097-.861 3.773 3.773 0 013.774-3.773 3.77 3.77 0 012.756 1.191 7.602 7.602 0 002.397-.916 3.789 3.789 0 01-1.66 2.088 7.55 7.55 0 002.168-.594 7.623 7.623 0 01-1.884 1.953z"/>
      </svg>
    </a>
  </StyledSocialBar>
)

const parseContent = (input) => {
  if (input?.name === 'form') {
    return (
      <form class="ecommerceForm" id="ecommerceForm" method="post" action="https://getform.io/f/30d07f7b-c03f-46db-ac68-e0a0f5d8effb">
        <div class="row">
          <div class="col-12">
            <h2 class="title">Unlock your<br></br>E-commerce Growth</h2>
          </div>
          <div class="col-6">
            <label htmlFor="contact-full-name" style={{ position: 'absolute', fontSize: '0px' }}>Name *</label>
            <input required placeholder="Name*" id="contact-full-name" name="fullname" type="text" style={{ backgroundImage: 'url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAfBJREFUWAntVk1OwkAUZkoDKza4Utm61iP0AqyIDXahN2BjwiHYGU+gizap4QDuegWN7lyCbMSlCQjU7yO0TOlAi6GwgJc0fT/fzPfmzet0crmD7HsFBAvQbrcrw+Gw5fu+AfOYvgylJ4TwCoVCs1ardYTruqfj8fgV5OUMSVVT93VdP9dAzpVvm5wJHZFbg2LQ2pEYOlZ/oiDvwNcsFoseY4PBwMCrhaeCJyKWZU37KOJcYdi27QdhcuuBIb073BvTNL8ln4NeeR6NRi/wxZKQcGurQs5oNhqLshzVTMBewW/LMU3TTNlO0ieTiStjYhUIyi6DAp0xbEdgTt+LE0aCKQw24U4llsCs4ZRJrYopB6RwqnpA1YQ5NGFZ1YQ41Z5S8IQQdP5laEBRJcD4Vj5DEsW2gE6s6g3d/YP/g+BDnT7GNi2qCjTwGd6riBzHaaCEd3Js01vwCPIbmWBRx1nwAN/1ov+/drgFWIlfKpVukyYihtgkXNp4mABK+1GtVr+SBhJDbBIubVw+Cd/TDgKO2DPiN3YUo6y/nDCNEIsqTKH1en2tcwA9FKEItyDi3aIh8Gl1sRrVnSDzNFDJT1bAy5xpOYGn5fP5JuL95ZjMIn1ya7j5dPGfv0A5eAnpZUY3n5jXcoec5J67D9q+VuAPM47D3XaSeL4AAAAASUVORK5CYII=&quot;)', backgroundRepeat: 'no-repeat', backgroundAttachment: 'scroll', backgroundSize: '16px 18px', backgroundPosition: '98% 50%', cursor: 'auto' }}/>
          </div>
          <div class="col-6">
            <label htmlFor="contact-email" style={{ position: 'absolute',  fontSize: '0px' }}>
              Email *
            </label>
            <input required placeholder="Email*" id="contact-email" type="text" name="email"/>
          </div>
          <div class="col-6">
            <label htmlFor="contact-phone" style={{ position: 'absolute',  fontSize: '0px' }}>Phone</label>
            <input id="contact-phone" type="telephone" name="phone" placeholder="Phone Number (optional)"/>
          </div>
          <div class="col-6">
            <label htmlFor="contact-company" style={{ position: 'absolute',  fontSize: '0px' }}>Company</label>
            <input id="contact-company" type="text" name="company" placeholder="Company Name (optional)"/>
          </div>
          <div class="col-6" style={{ display: 'none'}}>
            <label htmlFor="contact-url" style={{ position: 'absolute',  fontSize: '0px' }}>Url</label>
            <input id="contact-url" type="text" name="url" value="E-commerce Growth"/>
          </div>
          <div class="col-12 left">
            <label htmlFor="contact-message">Your Message * </label>
            <textarea required id="contact-message" name="message" rows="10" placeholder="What can we help you with? Any info (such as project type, timing and budget) helps!"></textarea>
            </div>
            <div class="col-12">
              <button type="submit">Submit</button>
            </div>
          </div>
        <SocialBar />
      </form>

    );
  }
  return domToReact(input);
}

const getRandomIntInclusive = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
}


const EcommerceLandingPage = ({ data }) => {
  const testimonials = data.contact_page_fields.testimonials;
  const randomTestimonial = testimonials[getRandomIntInclusive(0, testimonials.length - 1)].testimonial;

  return (
    <Layout>
      <Seo title={'Unlock your Ecommerce Growth'} url='ecommerce-landing'/>
      <StyledContactPage>
        <Container fluid>
          <Row>
            <Col col={12} noGutter>
            {parse(data.content, { replace: node => parseContent(node) })}
            <SocialBar/>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col col={12}>
              <LogoBar>
                {data.contact_page_fields.logoBar.map(({ logo: { mediaItemUrl } }, i) => (
                  <div key={`${mediaItemUrl}-${i}`}>
                    <img src={mediaItemUrl} alt="logo" />
                  </div>
                ))}
              </LogoBar>
              <Margin margin={32} />
            </Col>
          </Row>
          <Row>
            <Col col={4} offset={4}>
              <hr/>
              <Margin margin={32} />
            </Col>
          </Row>
          <Row>
            <Col col={12} lg={6} lgOffset={3} style={{ textAlign: 'center' }}>
              {parse(randomTestimonial)}
              <Margin margin={64} />
            </Col>
          </Row>
            <Row>
              <Col col={12}>
                <OurWork>
                  <strong>
                    Check Out Our Portfolio
                  </strong>
                </OurWork>
              </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col col={12}>
              <ClientsRow />
            </Col>
          </Row>
        </Container>
      </StyledContactPage>
    </Layout>
  )
}

export default EcommerceLandingPage
