import React from "react";
import Layout from "../../components/layout";
import parse from 'html-react-parser';
import Seo from "../../components/seo";
import ServicesTemplate from '../Services';
import PortfolioTemplate from "../Portfolio"
import AboutTemplate from "../About"
import ContactPage from "../ContactPage"
import NewsletterPage from "../NewsletterPage"
import ShopifyNewsletterPage from "../ShopifyNewsletterPage"
import EcommerceLandingPage from "../EcommerceLandingPage"
import LandingPage from "../LandingPage"
import { StyledDefaultPage } from "./styles"
import { Container, Row, Col } from "styled-bootstrap-grid"
import { graphql } from 'gatsby';

const PageTemplate = ({ data: { wpPage } }) => {

  const id  = wpPage.id;
  const { ACFPageType: { pageType: pageTypeArray } } = wpPage;
  const [pageType] = pageTypeArray
  const customStyles = {};

  if (pageType === "About") {
    return <AboutTemplate data={wpPage} />;
  }
  if (pageType === "Service") {
    return <ServicesTemplate data={wpPage} />;
  }
  if (pageType === "Portfolio") {
    return <PortfolioTemplate data={wpPage} />;
  }
  if (pageType === "Contact") {
    return <ContactPage data={wpPage} />;
  }
  if (pageType === "Newsletter") {
    return <NewsletterPage data={wpPage} data-page-id={id}/>;
  }
  if (pageType === "ShopifyNewsletter") {
    return <ShopifyNewsletterPage data={wpPage} data-page-id={id}/>;
  }
  if (pageType === "EcommerceLandingPage") {
    return <EcommerceLandingPage data={wpPage} data-page-id={id}/>;
  }
  if (pageType === "Landing Page") {
    return <LandingPage data={wpPage} />;
  }
  if (pageType === "ThankYou") {
    customStyles.margin = '10rem 0';
    customStyles.textAlign = 'center';
  }
  return (
    <Layout>
      <Seo title={wpPage?.title ?? 'Page'} url={wpPage.slug}/>
      <Container>
        <Row>
          <Col col={12} style={customStyles}>
            <StyledDefaultPage>
              <h1>{wpPage?.title ?? ''}</h1>
              {parse(wpPage?.content ?? '')}
            </StyledDefaultPage>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default PageTemplate;

export const query = graphql`
  query($id: String!) {
    wpPage(id: {eq: $id}) {
      ACFPageType {
        pageType
      }
      landing_page_fields {
        title
        subtitle
        content
        faq {
          header
          content {
            qa {
              question
              answer
            }
          }
        }
        landingContentBlocks {
          content
          linkLabel
          linkUrl
          title
          image {
            mediaItemUrl
          }
          fieldGroupName
        }
      }
      contact_page_fields {
        logoBar {
          fieldGroupName
          logo {
            mediaItemUrl
          }
        }
        contentBlocks {
          content
          linkLabel
          linkUrl
          title
          image {
            mediaItemUrl
          }
          fieldGroupName
        }
        testimonials {
          fieldGroupName
          testimonial
        }
      }
      ACFServicesTemplateOptions {
        tileGridHeading
        tileGridSubheading
        ourWorkHeading
        ourWorkSubheading
        tileGrid {
          tile
          tileUrl {
            ... on WpPage {
              id
              slug
            }
          }
        }
      }
      ACFFeaturedTestimonialsCards {
        featuredVerticalsTitle
        featuredVerticalsDescription
        featuredVerticals {
          image {
            altText
            mediaItemUrl
          }
          title
          description
          link {
            ... on WpPage {
              id
              slug
            }
            ... on WpPost {
              id
              slug
            }
          }
        }
        sectionBackgroundColor
      }
      title
      content
      id
      slug
      template {
        templateName
      }
      featuredImage {
        node {
          altText
          localFile {
            publicURL
          }
        }
      }
    }
  }
`
