import React, { Component } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';

export default class FormDataComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      EMAIL: 'Email',
      FNAME: 'fName',
      LNAME: 'Lname'
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    this.setState({
      ...this.state,
      [name]: value
    });
    return this.state;
  }

  // Since `addToMailchimp` returns a promise, you
  // can handle the response in two different ways:


  // Note that you need to send an email & optionally, listFields
  // these values can be pulled from React state, form fields,
  // or wherever.  (Personally, I recommend storing in state).

  // 1. via `.then`
  _handleSubmit = event => {
    event.preventDefault();
    console.log(this.state.EMAIL);

    addToMailchimp(
      this.state.EMAIL,
      'https://ambaum.us11.list-manage.com/subscribe/post?u=2fd1f700c78fd34175ef53078&amp;id=17378982cd&amp;f_id=00a698e0f0',
      ) // listFields are optional if you are only capturing the email address.
    .then(data => {
      // I recommend setting data to React state
      // but you can do whatever you want (including ignoring this `then()` altogether)
      const success = document.getElementById('mce-success-response');
      if (data.result === 'success') {
        console.log(data);
        success.innerText = data.msg;
        success.classList.removeClass('d-none');
      }
    });
  }

  render() {
    return (
      <form onSubmit={this._handleSubmit}>
         <div id="mc_embed_signup_scroll">
        <h2>Sign up for all things Shopify!</h2>
      <h3 className="subtitle">There is an incredible amount of upgrades rolled out by Shopify in their new Editions for the Summer of ‘22. From reporting, to flexible storefronts, to net terms and wholesale pricing, this all-in-one commerce solution is sure to dazzle. Sign up now to unlock immediate access to the full low-down and next steps for your Shopify Plus store.</h3>
      <div className="mc-field-group">
        <label htmlFor="mce-EMAIL">Email Address  <span className="asterisk">*</span>
      </label>
        <input type="email" defaultValue={this.state.email} onChange={this.handleInputChange} name="EMAIL" placeholder="Email*" className="required email" id="mce-EMAIL"/>
      </div>
      <div className="mc-field-group">
        <label htmlFor="mce-FNAME">First Name </label>
        <input type="text" defaultValue={this.state.fname} onChange={this.handleInputChange} name="FNAME" className="" placeholder="First Name" id="mce-FNAME"/>
      </div>
      <div className="mc-field-group">
        <label htmlFor="mce-LNAME">Last Name </label>
        <input type="text" defaultValue={this.state.lname} onChange={this.handleInputChange} name="LNAME" className="" placeholder="Last Name" id="mce-LNAME"/>
      </div>
        <div id="mce-responses" className="clear">
          <div className="response d-none" id="mce-error-response"></div>
          <div className="response d-none" id="mce-success-response"></div>
        </div>
          <div aria-hidden="true"><input type="text" name="b_2fd1f700c78fd34175ef53078_17378982cd" tabIndex="-1" value=""/></div>
          <div className="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button-cs"/></div>
          </div>
      </form>
    )
  }
}
