import React from "react"
import parse, { domToReact } from "html-react-parser"
import { Container, Col, Row } from "styled-bootstrap-grid"
import ContactBlock from "../../components/Contact"
import {
  Fallback,
  StyledPortfolioTemplate
} from "./styles"
import ClientGrid from "../../components/ClientsGrid"
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import { ContentWrap, FixedContent, HeroBlock, YellowBorderWrap } from "../Services/styles"

const PortfolioTemplate = ({ data }) => {
  const body = [];
  const h2 = [];
  const h1 = [];

  const parseContent = (input) => {
    if (input?.name === 'h1') {
      h1.push(input)
    }
    if (input?.name === 'h2') {
      h2.push(input)
    }
    if (input?.name === 'p'
      || input?.name === 'ul'
      || input?.name === 'table'
      || input?.name === 'h3'
      || input?.name === 'h4'
      || input?.name === 'h5'
      || input?.name === 'h6'
    ) {
      body.push(input)
    }
    if (input?.attribs?.class?.includes("gatsby-image-wrapper")) {
      body.push(input)
    }
  }

  parse(data?.content, { replace: node => parseContent(node) });
  const hero = data?.featuredImage?.node?.localFile?.publicURL;
  return (
    <Layout>
      <Seo title={data?.title ?? 'Page'} url={data.slug}/>
      <StyledPortfolioTemplate>
        <HeroBlock fluid>
          {hero ? (
            <img src={hero} alt="" />
            ) : (
              <Fallback />
            )
          }
          <FixedContent>
            <Container>
              <Row>
                <Col col={12}>
                  <YellowBorderWrap missingHero={!hero}>
                    {h1.length ? domToReact(h1) : <h1 style={{ color: '#ffffff' }}>{data?.title}</h1>}
                    {domToReact(h2)}
                  </YellowBorderWrap>
                </Col>
              </Row>
            </Container>
          </FixedContent>
        </HeroBlock>
        <Container>
          <Row>
            <Col col={12}>
              <ContentWrap>
                {domToReact(body)}
              </ContentWrap>
            </Col>
          </Row>
        </Container>
        <ClientGrid maxLength={100} />
      </StyledPortfolioTemplate>
      <Container fluid>
        <Row>
          <Col col={12} noGutter>
            <ContactBlock />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default PortfolioTemplate
