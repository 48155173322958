import React from 'react'
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import { Container, Col, Row } from "styled-bootstrap-grid"
import parse, { domToReact } from 'html-react-parser'
import {
  StyledContactPage,
  LogoBar,
  TextBox,
  SquareImg,
  Margin,
  StyledSocialBar
} from "./styles"
import { CardFooter } from '../../components/Card/styles'
import ClientsRow from '../../components/ClientsRow'
import { OurWork } from '../Services/styles'
import NewsletterSignupForm from '../../components/NewsletterSignupForm'

const SocialBar = () => (
  <StyledSocialBar>
    <a aria-label="LinkedIn profile" href="https://www.linkedin.com/company/ambaumlabs/" target='_blank' rel="noopener noreferrer">
      <svg viewBox="0 0 448 512" height="32" width="32" focusable="false" role="img" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg" className="StyledIconBase-ea9ulj-0 duvCfz"><title>Linkedin
        icon</title>
        <path fill="currentColor"
d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
      </svg>
    </a>
    <a aria-label="Facebook profile" href="https://facebook.com/ambaumlabs" target='_blank' rel="noopener noreferrer">
      <svg viewBox="0 0 20 20" height="32" width="32" focusable="false" role="img" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg" className="StyledIconBase-ea9ulj-0 duvCfz"><title>Facebook
        icon</title>
        <path
d="M17 1H3c-1.1 0-2 .9-2 2v14c0 1.101.9 2 2 2h7v-7H8V9.525h2v-2.05c0-2.164 1.212-3.684 3.766-3.684l1.803.002v2.605h-1.197c-.994 0-1.372.746-1.372 1.438v1.69h2.568L15 12h-2v7h4c1.1 0 2-.899 2-2V3c0-1.1-.9-2-2-2z"/>
      </svg>
    </a>
    <a aria-label="Instagram profile" href="https://instagram.com/ambaumlabs" target='_blank' rel="noopener noreferrer">
      <svg viewBox="0 0 18 18" height="32" width="32" focusable="false" role="img" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg" className="StyledIconBase-ea9ulj-0 duvCfz">
        <title>Instagram icon</title>
        <path
d="M17.946 5.29a6.606 6.606 0 00-.418-2.185 4.412 4.412 0 00-1.039-1.594A4.412 4.412 0 0014.895.472a6.606 6.606 0 00-2.184-.418C11.75.01 11.444 0 9 0S6.25.01 5.29.054a6.606 6.606 0 00-2.185.418A4.412 4.412 0 001.51 1.511 4.412 4.412 0 00.472 3.105a6.606 6.606 0 00-.418 2.184C.01 6.25 0 6.556 0 9s.01 2.75.054 3.71a6.606 6.606 0 00.418 2.185 4.412 4.412 0 001.039 1.594 4.411 4.411 0 001.594 1.039 6.606 6.606 0 002.184.418C6.25 17.99 6.556 18 9 18s2.75-.01 3.71-.054a6.606 6.606 0 002.185-.418 4.602 4.602 0 002.633-2.633 6.606 6.606 0 00.418-2.184C17.99 11.75 18 11.444 18 9s-.01-2.75-.054-3.71zm-1.62 7.347a4.978 4.978 0 01-.31 1.67 2.98 2.98 0 01-1.708 1.709 4.979 4.979 0 01-1.671.31c-.95.043-1.234.052-3.637.052s-2.688-.009-3.637-.052a4.979 4.979 0 01-1.67-.31 2.788 2.788 0 01-1.036-.673 2.788 2.788 0 01-.673-1.035 4.978 4.978 0 01-.31-1.671c-.043-.95-.052-1.234-.052-3.637s.009-2.688.052-3.637a4.979 4.979 0 01.31-1.67 2.788 2.788 0 01.673-1.036 2.788 2.788 0 011.035-.673 4.979 4.979 0 011.671-.31c.95-.043 1.234-.052 3.637-.052s2.688.009 3.637.052a4.979 4.979 0 011.67.31 2.788 2.788 0 011.036.673 2.788 2.788 0 01.673 1.035 4.979 4.979 0 01.31 1.671c.043.95.052 1.234.052 3.637s-.009 2.688-.052 3.637zM9 4.378A4.622 4.622 0 1013.622 9 4.622 4.622 0 009 4.378zM9 12a3 3 0 113-3 3 3 0 01-3 3zm5.884-7.804a1.08 1.08 0 11-1.08-1.08 1.08 1.08 0 011.08 1.08z"/>
      </svg>
    </a>
    <a aria-label="Twitter profile" href="https://twitter.com/ambaumlabs1" target="_blank" rel="noopener noreferrer">
      <svg viewBox="0 0 20 20" height="32" width="32" focusable="false" role="img" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg" className="StyledIconBase-ea9ulj-0 duvCfz"><title>Twitter
        icon</title>
        <path
d="M17.316 6.246c.008.162.011.326.011.488 0 4.99-3.797 10.742-10.74 10.742-2.133 0-4.116-.625-5.787-1.697a7.577 7.577 0 005.588-1.562 3.779 3.779 0 01-3.526-2.621 3.858 3.858 0 001.705-.065 3.779 3.779 0 01-3.028-3.703v-.047a3.766 3.766 0 001.71.473 3.775 3.775 0 01-1.168-5.041 10.716 10.716 0 007.781 3.945 3.813 3.813 0 01-.097-.861 3.773 3.773 0 013.774-3.773 3.77 3.77 0 012.756 1.191 7.602 7.602 0 002.397-.916 3.789 3.789 0 01-1.66 2.088 7.55 7.55 0 002.168-.594 7.623 7.623 0 01-1.884 1.953z"/>
      </svg>
    </a>
  </StyledSocialBar>
)

const parseContent = (input) => {
  if (input?.name === 'form') {
    return (
      <div class="wrapper">
<NewsletterSignupForm/>
</div>
  );
}
else if (input?.name === 'socialbar') {
  return (
    <SocialBar/>
  )
}
return domToReact(input);
}

const getRandomIntInclusive = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
}


const NewsletterPage = ({ data }) => {
  const testimonials = data.contact_page_fields.testimonials;
  const randomTestimonial = testimonials[getRandomIntInclusive(0, testimonials.length - 1)].testimonial;

  return (
    <Layout>
      <Seo title={'Newsletter Sign Up'} url='newsletter'/>
      <StyledContactPage>
        <Container fluid>
          <Row>
            <Col col={12} noGutter>    
            {parse(data.content, { replace: node => parseContent(node) })}  
            <SocialBar/>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col col={12}>
              <LogoBar>
                {data.contact_page_fields.logoBar.map(({ logo: { mediaItemUrl } }, i) => (
                  <div key={`${mediaItemUrl}-${i}`}>
                    <img src={mediaItemUrl} alt="logo" />
                  </div>
                ))}
              </LogoBar>
              <Margin margin={32} />
            </Col>
          </Row>
          <Row>
            <Col col={4} offset={4}>
              <hr/>
              <Margin margin={32} />
            </Col>
          </Row>
          <Row>
            <Col col={12} lg={6} lgOffset={3} style={{ textAlign: 'center' }}>
              {parse(randomTestimonial)}
              <Margin margin={64} />
            </Col>
          </Row>
            {data.contact_page_fields.contentBlocks.map(({
              title,
              content,
              image,
              linkLabel,
              linkUrl
            }, i) => (
              <Row key={`${title}-${i}`}>
                <Col col={12} md={6} mdOrder={i % 2 ? 2 : 1}>
                  <SquareImg>
                    <img src={image.mediaItemUrl} alt="thumbail" />
                  </SquareImg>
                </Col>
                <Col col={12} md={6} mdOrder={i % 2 ? 1 : 2}>
                  <TextBox to={linkUrl}>
                    <strong>{title}</strong>
                    <hr />
                    <p>
                      {parse(content)}
                    </p>
                    <CardFooter>
                      <span>{linkLabel}</span>
                      <svg viewBox="0 0 16 16" height="32" width="32" focusable="false" role="img" fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg" className="StyledIconBase-ea9ulj-0 duvCfz"><title>ArrowRightShort
                        icon</title>
                        <path fillRule="evenodd"
                d="M4 8a.5.5 0 01.5-.5h5.793L8.146 5.354a.5.5 0 11.708-.708l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L10.293 8.5H4.5A.5.5 0 014 8z"/>
                      </svg>
                    </CardFooter>
                  </TextBox>
                </Col>
                <Col col={12}>
                  <Margin margin={32}/>
                </Col>
              </Row>
            ))}
            <Row>
              <Col col={12}>
                <OurWork>
                  <strong>
                    Check Out Our Portfolio
                  </strong>
                </OurWork>
              </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col col={12}>
              <ClientsRow />
            </Col>
          </Row>
        </Container>
      </StyledContactPage>
    </Layout>
  )
}

export default NewsletterPage
