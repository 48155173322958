import styled from 'styled-components';
import { Link } from 'gatsby';
import { media } from "styled-bootstrap-grid"

export const StyledClientRow = styled.ul`
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-left: -8px;
  margin-right: -8px;
  li {
    width: 100%;
    line-height: 1.5;
    position: relative;
    padding-top: 55%;
    margin: 8px;
    ${media.md`
      width: calc(50% - 16px);
      padding-top: calc(55% / 2);
    `}
    ${media.lg`
      width: calc((100% / 3) - 16px);
      padding-top: calc(55% / 3);
    `}
    > a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img {
          height: 100%;
      }
    }
}
`

export const StyledClientTile = styled(Link)`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  color: white;
  text-decoration: none;
  img {
    height: 240px;
    object-fit: cover;
  }
  .tags {
    opacity: 0;
    transition: 0.33s;
    display: none;
  }
  svg {
    transition: 0.3s;
    transform: scaleX(1);
    margin-right: 8px;
  }
  ${media.md`
    .tags {
      display: block;
    }
    &:hover {
      .tags {
        opacity: 1;
      }
      svg {
        transform: scale(1.1);
        margin-right: 0;
      }
    }
  `}
`

export const LogoWrap = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  width: 220px;
  height: 80px;
  z-index: 1;
  img {
    object-fit: scale-down;
    object-position: center left;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`

export const CardFooter = styled.div`
  background-color: ${({ bg }) => bg};
  padding: 12px;
  z-index: 1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  p {
    margin: 0;
  }
`

export const Tags = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left:0;
  ul {
    position: absolute;
    top: 90px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    z-index: 1;
    list-style-type: none;
    margin: 0;
    padding: 15px;
    width: 100%;
    height: auto;
    > li {
      width: auto;
      margin: 0;
      width: auto;
      white-space: nowrap;
      padding-top: 0;
      ${media.md`
        flex: none;
        padding-top: 0;
      `}
      
      &:nth-child(1), &:nth-child(2) {
        padding-top: 0;
        ${media.md`
          padding-top: 0;
          width: auto;
        `}
      }
      
      > a {
        position: static;
        color: white;
        text-decoration: none;
        transition: 0.2s;
        border-bottom: 2px solid transparent;
        height: auto;
        &:hover {
          border-color: white;
        }
        ${media.md`
          position: static;
        `}
      }
      &:not(:first-child) {
        &:before {
          color: white;
          content: "/";
          padding: 0 8px;
        }
      }
    }
  }
  &:before {
    content: "";
    background-color: ${({ bg }) => bg};
    opacity: 0.8;
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left:0;
  }
`

export const LinkWrap = styled.div`
  text-align: center;
  padding: 16px;
  a {
    color: #479FDB;
    font-size: 18px;
    text-decoration: none;
    svg {
      fill: #479FDB;
      transition: 0.2s;
    }
    &:hover {
      color: #206ea2;
      svg {
        transform: scale(1.1) translateX(4px);
      }
    }
  }
`
