import styled from 'styled-components'
import { StyledServicesTemplate } from "../Services/styles"
import { media } from "styled-bootstrap-grid"

export const StyledAboutTemplate = styled(StyledServicesTemplate)`
  padding-bottom: 80px;

  h1.wp-block-heading {
    color: white;
    text-align: center;
    text-shadow: 1px 1px 0px #333;
    ${media.md`
        font-size: 50px;
    `};
    ${media.lg`
        font-size: 72px;
        letter-spacing: 3.35px;
    `};
  }
  h2.wp-block-heading {
    color: white;
    text-align: center;
    margin: 3rem auto auto;
    max-width: 820px;
    width: 100%;
    text-shadow: 1px 1px 0px #333;
  }
  .img-wrap {
    padding-bottom: 20px;
  }
`

export const EmployeesGrid = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 32px 0;
`

export const EmployeeTile = styled.div`
  display: block;
  background-color: white;
  border-radius: 2px;
  width: calc(100% - 32px);
  margin: 16px;
  padding: 16px 32px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);  text-align: center;
  img {
    padding: 32px;
  }
  a {
    color: #2867B2;
  }
  p, strong {
    margin: 0;
    padding: 0;
    padding: 8px 0;
  }
  p {
    text-align: left;
  }
  ${media.md`
    width: calc((100% / 3) - 32px);
  `}
`
