import styled from 'styled-components'
import { media } from "styled-bootstrap-grid"
import { StyledServicesTemplate } from "../Services/styles"

export const StyledPortfolioTemplate = styled(StyledServicesTemplate)`
  padding-bottom: 32px;
  ${media.md`
    padding-bottom: 64px;
  `}
`

export const Fallback = styled.div`
  padding-top: 25%;
  width: 100%;
  background-color: #F9F9F9;
`